var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:['panel', 'themes',
		_vm.isHeaderIndent? 'mt-0 pt-1' : '',
		_vm.isFooterIndent? 'mb-0 pb-0' : '',
		_vm.block.appearance? _vm.block.appearance : ''
	]},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[(_vm.isHeaderVisible)?_c('div',{class:[
					'col-12 panel_header',
					_vm.isBrand? 'sk' : '',
				]},[_c('h2',[_vm._v("\n\t\t\t\t\t"+_vm._s(_vm.block.block_title)+"\n\t\t\t\t")])]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"col-12 col-lg-12"},[_c('div',{staticClass:"gallery_slider gallery-top"},[_c('CoolLightBox',{attrs:{"items":_vm.imageSources,"full-screen":true,"index":_vm.imageIndex},on:{"close":_vm.lightBoxClosed}}),_vm._v(" "),_c('vueper-slides',{ref:"vueperslides1",staticClass:"no-shadow",attrs:{"slide-ratio":1 / 6,"visible-slides":3,"dragging-distance":200,"gap":2,"touchable":true,"autoplay":true,"bullets":false,"loop":true,"arrows-outside":false,"breakpoints":_vm.breakpoints,"lazy":"","lazy-load-on-drag":""},on:{"slide":function($event){_vm.$refs.vueperslides2 && _vm.$refs.vueperslides2.goToSlide($event.currentSlide.index, { emit: false })}}},_vm._l((_vm.block.images),function(item,index){return _c('vueper-slide',{key:item.id,staticClass:"open-lightbox",attrs:{"image":item.src,"show":true,"data-slide-index":index},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('div',{staticClass:"zoom-btn icon-resize_open"}),_vm._v(" "),_c('div',{staticClass:"slider__footer"},[_c('div',{staticClass:"title text-white mb-3"},[_vm._v("\n\t\t\t\t\t\t\t\t\t\t"+_vm._s(item.name)+"\n\t\t\t\t\t\t\t\t\t")])])]},proxy:true},{key:"loader",fn:function(){return [_c('div',{staticClass:"d-flex justify-content-center",staticStyle:{"width":"100%"}},[_c('b-spinner',{staticStyle:{"margin":"0 auto","width":"3em","height":"3em"}})],1),_vm._v(" "),_c('span',[_vm._v("Загрузка...")])]},proxy:true}],null,true)})}),1)],1)])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }