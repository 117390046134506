<template>
	<div
		:class="['panel', 'themes',
			isHeaderIndent? 'mt-0 pt-1' : '',
			isFooterIndent? 'mb-0 pb-0' : '',
			block.appearance? block.appearance : ''
		]"
	>
		<div class="container">
			<div class="row">
				<div
					v-if="isHeaderVisible"
					:class="[
						'col-12 panel_header',
						isBrand? 'sk' : '',
					]"
				>
					<h2>
						{{ block.block_title }}
					</h2>
				</div>
				<div class="col-12 col-lg-12">
					<div class="gallery_slider gallery-top">
						<CoolLightBox
							:items="imageSources"
							:full-screen="true"
							:index="imageIndex"
							@close="lightBoxClosed"
						/>

						<vueper-slides
							ref="vueperslides1"
							:slide-ratio="1 / 6"
							:visible-slides="3"
							:dragging-distance="200"
							:gap="2"
							:touchable="true"
							:autoplay="true"
							:bullets="false"
							:loop="true"
							:arrows-outside="false"
							:breakpoints="breakpoints"
							class="no-shadow"
							lazy
							lazy-load-on-drag

							@slide="$refs.vueperslides2 && $refs.vueperslides2.goToSlide($event.currentSlide.index, { emit: false })"
						>
							<vueper-slide
								v-for="(item, index) in block.images"
								:key="item.id"
								:image="item.src"
								:show="true"
								:data-slide-index="index"
								class="open-lightbox"
							>
								<template #content>
									<div class="zoom-btn icon-resize_open"></div>
									<div class="slider__footer">
										<div class="title text-white mb-3">
											{{ item.name }}
										</div>
									</div>
								</template>
								<template #loader>
									<div class="d-flex justify-content-center" style="width: 100%">
										<b-spinner style="margin: 0 auto; width: 3em; height: 3em;" />
									</div>
						      <span>Загрузка...</span>
						    </template>
							</vueper-slide>
						</vueper-slides>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import panel from '~/mixins/panel';
import CoolLightBox from 'vue-cool-lightbox';
import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css';
import { VueperSlides, VueperSlide } from 'vueperslides';
import 'vueperslides/dist/vueperslides.css';

const LOOP_TRANSITION_DURATION = 500;

export default {
	name: 'Gallery',
	components: {
		VueperSlides,
		VueperSlide,
		CoolLightBox,
	},
	mixins: [panel],
	props: {
		images: {
			type: Array,
			default: null,
		},
		status: {
			type: Boolean,
			default: true,
		},
	},
	data() {
		return {
			imageIndex: null,
			pauseOnHover: true,
		  autoPlaying: true,
		  internalAutoPlaying: true,
			breakpoints: {
				576: {
					visibleSlides: 1,
					slideRatio: 1 / 2,
				},
				768: {
					visibleSlides: 2,
					slideRatio: 1 / 4,
				},
				992: {
					visibleSlides: 2,
					slideRatio: 1 / 4,
				},
				1260: {
					visibleSlides: 2,
					slideRatio: 1 / 4,
				},
			},
		};
	},
	mounted() {
		this.addListenersToZoomBtns();
	},
	computed: {
		imageSources() {
			return this.block.images.map((item) => item.src);
		},
	},
	methods: {
		slideToLoop(index) {
			this.Slider.slideToLoop(index, LOOP_TRANSITION_DURATION, false);
		},
		zoomIn(index) {
			this.imageIndex = index;
			this.$nextTick(() => {
				this.slideToLoop(index);
			});
		},
		lightBoxClosed(index) {
			this.imageIndex = null;
			this.$nextTick(() => {
				this.addListenersToZoomBtns();
				this.slideToLoop(index);
			});
		},
		addListenersToZoomBtns() {
			const zoomButtons = document.getElementsByClassName('open-lightbox');
			const buttonsArray = [...zoomButtons];

			buttonsArray.forEach((item) => {
				const index = +item.getAttribute('data-slide-index');
				item.addEventListener('click', () => {
					this.zoomIn(index);
				}, { once: true });
			});
		},
	},
};
</script>

<style lang="scss" scoped>
	@import '~/assets/styles/components/blocks/gallery';
</style>
